import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import DefaultHeader from 'components/DefaultHeader';
import StorageExplorerContent from 'components/mobile-app/StorageExplorerContent';
import MobileAppIcon from 'assets/img/object-storage-explorer/icon.png';
import SupportSection from 'components/mobile-app/SupportSection';

export default function StorageExplorer() {
    return (
        <>
            <div className="absolute w-full z-20">
                <DefaultNavbar/>
            </div>
            <main>
                <DefaultHeader bg="bg-object-storage-explorer-background" />
                <StorageExplorerContent title="Object Storage Explorer" icon={MobileAppIcon}/>
                <SupportSection />
            </main>
            <DefaultFooter />
        </>
    );
}

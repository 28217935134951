import H3 from '@material-tailwind/react/Heading3';
import Paragraph from '@material-tailwind/react/Paragraph';
import Input from '@material-tailwind/react/Input';
import Textarea from '@material-tailwind/react/Textarea';
import Button from '@material-tailwind/react/Button';
import { useState } from 'react';
//import fetch from 'node-fetch';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
  } from "@material-tailwind/react";



export default function SupportSection() {
    const [state, setState] = useState({})
    const [size, setSize] = useState(null);
    const [modalActive, setModalActive] = useState(false);
    const handleSubmit = (e)=>{
        console.log('handle submit');

        // Details of the uploaded file
        //console.log(this.state.selectedFile);

        // Request made to the backend api
        // Send formData object
        fetch("https://dq.dreamfighter.id/support", {
            method: 'post',
            body: JSON.stringify(state),
            headers: {'Content-Type': 'application/json'}
        }).then(e=>{
            console.log(e.body.success);
            handleDialog(true);
        }).catch(e=>{
            handleDialog(true);
            console.log(e);
        });

        e.preventDefault();
    }

    const handleChange = (e)=>{
        state[e.target.name] = e.target.value;
        setState(state)
        console.log(state);
    }

    const handleDialog = (value) => {
        console.log(value);
        setModalActive(true);
        setSize(value);
    };

    const handleCloseModal = (value) => {
        setModalActive(false);
    };

    return (
        <section className="pb-20 relative block bg-gray-100">
            <Modal
                active={modalActive}
                size="sm"
                toggler={handleDialog}
            >
                <ModalHeader toggler={handleCloseModal}>Thank You for contacting us!</ModalHeader>
                    <ModalBody>
                    We have received your message. We will follow up your message and get back to you as soon as possible.
                    </ModalBody>
                <ModalFooter>
                    <Button
                        variant="gradient"
                        color="green"
                        onClick={() => handleCloseModal(null)}
                    >
                        <span>OK</span>
                    </Button>
                </ModalFooter>
            </Modal>
            <div className="container max-w-7xl mx-auto px-4 lg:pt-24">
                <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-8/12 px-4">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
                            <div className="flex-auto p-5 lg:p-10">
                                <div className="w-full text-center">
                                    <H3 color="gray">How Can We Help?</H3>
                                    <Paragraph color="blueGray">
                                    We would love to hear from you! If you have queries or feedback for us, 
                                    please fill up this short form so that we can better assist you. 
                                    You may also drop us an email at support@dreamfighter.id.
                                    </Paragraph>
                                </div>
                                <form onSubmit={(e) => handleSubmit(e)}>
                                    <div className="flex gap-8 mt-16 mb-12">
                                        <Input
                                            type="text"
                                            name="name"
                                            placeholder="Full Name"
                                            color="lightBlue"
                                            onChange={handleChange}
                                        />
                                        <Input
                                            type="email"
                                            name="email"
                                            placeholder="Email Address"
                                            color="lightBlue"
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <Textarea color="lightBlue" placeholder="Message" name="msg" onChange={handleChange}/>

                                    <div className="flex justify-center mt-10">
                                        <Button color="lightBlue" ripple="light">
                                            Send Message
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
